import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth'

Vue.use(VueAxios, axios);

axios.defaults.baseURL = 'https://liven-sa.com/api';

//axios.defaults.baseURL = 'http://127.0.0.1:8000/api';

function getToken() {
    //return 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNjJiZmYyOWVhYjMwMmM1OGFhNDdjM2ZkNmNlMDQ4MTVhZTU1ODNiZTM3MzQ5MzA0NDM1MTE1NWUxMGM0ZmRmOTFjMDBiMWFhZWFlYmFhMmQiLCJpYXQiOiIxNjEwMDQyNDU5LjIxODMzMSIsIm5iZiI6IjE2MTAwNDI0NTkuMjE4MzM2IiwiZXhwIjoiMTY0MTU3ODQ1OS4yMTA2MDIiLCJzdWIiOiI0OTAiLCJzY29wZXMiOltdfQ.XRuXF8pzEggYZOi4Q1TcRXZPv_OLbctINiot3sV2-KqjA5bYzHiYY4z9E88_bV0tgzbIM-Iz1_Undz_1k9TJnJGFq2BaH-w6d13652PLVxAECKdBbCmIpAZtRe1LYgDBdOcPIIaTmqLEOWdWELEyiFtfYh_HCkgNNX1l92V7C5tpfvmqbFzRZTX7RLs3N1ZaFeGz_n4FKMW9yi5jY0y3IEo4KqmY2l4VmUPCfwBfNNyn27tckvNjA5QCMRrnniX6vBT0z7Y6o8l6fofmHPxNE_8rVaF9N8s_vr5Dkll3e7ay98QZtdXu1ZBvUl_39gYRs8tqhvr_uSvtMayxilxapMjinJ20Aq6KB7JbwzxolPn5IgARlmwzhrt2ExbTIMuVoWakC-EJfFeW4NxzAA0d3C04iw-_JTLigkSX_iOq3hek4W7qORaySaLFf4UEZkdSWbmZo6oF3CGh0rdgN5DEg98n68-BZbIlhonj7tfBPJNq73aNGSyPOqHlXrJ4JCg0rHI62ei-glh8IJIsy6_pLK4tVaAvn2hc07dpfQ77Vs3nAQGuRSTWYvnsumOt_zY5vRGJve8O4wX3CcC1p3YuaCmfPM1lJJNYPxUzW8TbhoslpxUUF-IqEDit_7BTvOSfgDVqvWZOcW6FD8BIxfC0RfF_RNH7ih28uzGyGG7k5qc';
       // return 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNGE1ZmMxMTk5Y2YzNmM4NDE3YTM3YjQzODZlM2Q3MmY3MjZmMmRiOTFlZjE1YmY0ZDAwNDdlZDg1NjQwMjY0ODUzYjRhMDVkYWMzYjkyN2MiLCJpYXQiOjE1ODcwNzAzMzksIm5iZiI6MTU4NzA3MDMzOSwiZXhwIjoxNjE4NjA2MzM5LCJzdWIiOiIxMTYiLCJzY29wZXMiOltdfQ.iR0Tzktkwvsa42zPg_R5UJzGXZvWCP7tcDSlaDVo00WiqiYCobrMh5fn76Pt0FrCJoYW_knEJiTfv3fodOoHTg';
      return 'Bearer ' + localStorage.getItem('token');
}

axios.defaults.headers.common['Authorization'] = getToken();

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = getToken();
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

Vue.use(VueAuth, {
    auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x'),
});
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        showMeal: false,
        menuOpen: false,
        openCall: false,
        token: null
    },
    mutations: {
        setMealShow(state, status) {
            state.showMeal = status
        },
        setOpenMenu: (state, item) => {
            state.menuOpen = item;
        },
        setCallOpen: (state, item) => {
            state.openCall = item;
        },
        setToken: (state, item) => {
            state.token = item;
        }
    },
    actions: {
        getMealShow({commit}, status) {
            commit('setMealShow', status)
        },
        getOpenMenu({commit}, item) {
            commit('setOpenMenu', item);
        },
        getCallOpen({commit}, item) {
            commit('setCallOpen', item);
        },
        getToken({commit}, item) {
            commit('setToken', item);
        },
    }
})
