<template>
    <div>
        <Bar :title="'التثقيف الصحي'" :close="'/inquiries-list'" :plus="false"/>
        <div class="mobile-padding pb-4 custom-padding min-h-screen bg-gray-100">
            <div class="mb-6">
                <h4 class="text-base text-blue-800 font-medium mb-3">المادة التعليمية</h4>
                <div class="mb-2" v-if="materials.length" v-for="(mat,i) in materials" :key="i">
                    <a v-if="mat.path" target="_blank"
                       class="relative block overflow-hidden rounded-15px mb-4" onclick="openEducationalMaterial(this)">
                        <div class="edu_material hidden">{{mat.path}}</div>
                        <div class="overlay flex items-center px-4 absolute inset-0 w-full h-full rounded-15px">
                            <p class="text-lg text-white-900 font-bold">الأسبوع {{mat.week_id}} </p>
                        </div>
                        <img class="w-full rounded-15px" src="@/assets/img/education.jpg" alt="">
                    </a>
                    <div v-else class="relative overflow-hidden rounded-15px mb-4">
                        <div class="overlay flex items-center  absolute inset-0 w-full h-full rounded-15px">
                            <p class="text-base text-white-900 font-bold px-4">لم يتم رفع ملف المادة التعليمية</p>
                        </div>
                        <img class="w-full rounded-15px" src="@/assets/img/education.jpg" alt="">
                    </div>
                    <div class="flex items-center justify-between mb-6">
                        <!-- <p class="timing">{{mat.created_at}}</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CustomCheckbox from '../../components/CustomCheckbox'
    import Bar from '../../components/app/Bar';
    import ConfirmDelete from '../../components/ConfirmDelete';

    export default {
        components: {
            CustomCheckbox,
            Bar,
        },
        data() {
            return {
                selectedTab: 1,
                materials: [],
            }
        },
        methods: {
            loadInquiries() {
                this.axios.get('/mobile/currentAllUserMaterial')
                    .then(response => {
                        this.materials = response.data.data;
                    })
            },
        },
        mounted() {
            this.loadInquiries();
        }
    }
</script>
<style scoped lang="scss">
    .active {
        > div {
            background-color: #693574;
            color: #fff;
        }
    }

    .timing {
        direction: ltr;
        color: #9A9CB8;
        font-size: 13px;
    }
</style>
