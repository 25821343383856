<template>
    <div>
        <Bar :title="'قياسات الجسم'" :close="'/'"/>
        <div class="mobile-padding pb-4 custom-padding min-h-screen bg-gray-100">
            <!--            <ul class="flex flex-wrap items-center -mx-2 mb-6">-->
            <!--                <li class="w-1/3 px-2 text-center">-->
            <!--                    <div @click="changeChart('week')" class="rounded-25px py-2"-->
            <!--                         :class="selectedChart==='week'?'bg-primary-900 text-white-900':'bg-white-900 text-primary-900'"-->
            <!--                    >-->
            <!--                        <span class="text-base font-medium">اسبوع</span>-->
            <!--                    </div>-->
            <!--                </li>-->
            <!--                <li class="w-1/3 px-2 text-center">-->
            <!--                    <div @click="changeChart('month')" class="rounded-25px py-2"-->
            <!--                         :class="selectedChart==='month'?'bg-primary-900 text-white-900':'bg-white-900 text-primary-900'"-->
            <!--                    >-->
            <!--                        <span class="text-base font-medium">شهر</span>-->
            <!--                    </div>-->
            <!--                </li>-->
            <!--                <li class="w-1/3 px-2 text-center">-->
            <!--                    <div @click="changeChart('year')" class="rounded-25px py-2"-->
            <!--                         :class="selectedChart==='year'?'bg-primary-900 text-white-900':'bg-white-900 text-primary-900'"-->
            <!--                    >-->
            <!--                        <span class="text-base font-medium">سنة</span>-->
            <!--                    </div>-->
            <!--                </li>-->
            <!--            </ul>-->
            <!--            <div class="mb-6">-->
            <!--                <h2 class="text-xl font-medium text-blue-800 mb-4 text-center">الوزن</h2>-->
            <!--                <highcharts :options="chartOptions" :style="'height: 250px;'"></highcharts>-->
            <!--                <p class="p-color text-xs flex items-center">-->
            <!--                    <span class="w-3 h-3 rounded-full bg-blue-800 ltr:mr-2 rtl:ml-2"></span>-->
            <!--                    الوزن-->
            <!--                </p>-->
            <!--            </div>-->

            <h4 class="text-center text-blue-800 text-base mb-4">قم بوضع قياسات الجسم الحاليه</h4>
            <div class="flex flex-wrap -mx-2 2xs:-mx-1">
                <div class="w-1/3 px-2 2xs:px-1">
                    <div class="bg-white-900 rounded-10px text-center py-4">
                        <img class="mx-auto" style="height: 39px;" src="@/assets/img/weights.svg" alt="">
                        <p class="text-base mt-4 text-blue-800">الوزن</p>
                    </div>
                </div>
                <div class="w-1/3 px-2 2xs:px-1">
                    <div class="bg-white-900 rounded-10px text-center py-4">
                        <img class="mx-auto" src="@/assets/img/hip.svg" alt="">
                        <p class="text-base mt-4 text-blue-800">محيط الورك</p>
                    </div>
                </div>
                <div class="w-1/3 px-2 2xs:px-1">
                    <div class="bg-white-900 rounded-10px text-center py-4">
                        <img class="mx-auto" src="@/assets/img/waist.svg" alt="">
                        <p class="text-base mt-4 text-blue-800">محيط الخصر</p>
                    </div>
                </div>
            </div>
            <div class="mb-4 mt-4">
                <div class="flex flex-wrap -mx-2 2xs:-mx-1">
                    <div class="w-1/3 px-2 2xs:px-1 relative edit-weight-box">
                        <div class="bg-white-900 rounded-10px text-center">
                            <div class="text-primary-900 flex items-center">
                                <span class="text-base font-bold text-purple-100 px-2">KG</span>
                                <Input class="text-lg font-bold flex-grow" v-model="form.weight"/>
                            </div>
                        </div>
                    </div><!--col-->
                    <div class="w-1/3 px-2 2xs:px-1 relative edit-weight-box">
                        <div class="bg-white-900 rounded-10px text-center">
                            <div class="text-primary-900 flex items-center">
                                <span class="text-base font-bold text-purple-100 px-2">CM</span>
                                <Input class="text-lg font-bold" style="direction: ltr;" v-model="form.highest"/>
                            </div>
                        </div>
                    </div><!--col-->
                    <div class="w-1/3 px-2 2xs:px-1 relative edit-weight-box">
                        <div class="bg-white-900 rounded-10px text-center">
                            <div class="text-primary-900 flex items-center">
                                <span class="text-base font-bold text-purple-100 px-2">CM</span>
                                <Input class="text-lg font-bold" style="direction: ltr;" v-model="form.waist"/>
                            </div>
                        </div>
                    </div><!--col-->
                </div>
                <p class="message-danger mt-2" v-if="errorMsg">لا يمكنك إضافة اكثر من وزن في نفس اليوم</p>
                <p class="message-danger mt-2" v-if="customError">الرجاء ملئ جميع الحقول المطلوبة</p>
            </div>
            <div class="mt-6 mb-6 text-center">
                <p class="text-base text-blue-800 text-center underline mb-4 inline-block" style="color: #9393AF;"
                   @click="openImageModal()">
                    طريقة أخذ القياس
                </p>
                <button type="submit" @click="addWeight"
                        class="w-full text-white-900 text-base font-medium bg-blue-800 rounded-25px py-3"
                        :class="loading?'btn-loading':''"
                        :disabled="loading">
                    <span>إضافة</span>
                    <div v-if="loading" class="spinner">
                        <div class="double-bounce1"></div>
                        <div class="double-bounce2"></div>
                    </div>
                </button>
            </div>

            <div v-if="bodyAll.length">
                <p class="text-center text-blue-800 text-base mb-3">القراءات السابقة</p>
                <div class="mb-4" v-for="(item,index) in bodyAll" :key="index">
                    <p class="text-xs p-color text-center">{{item.date}}</p>
                    <div class="flex flex-wrap -mx-2 2xs:-mx-1">
                        <div class="w-1/3 px-2 2xs:px-1 relative edit-weight-box">
                            <svg @click="openForm(item)" v-if="index===0 && !active && user !== '116'"
                                 aria-hidden="true"
                                 focusable="false"
                                 data-prefix="fas"
                                 data-icon="edit" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                 class="svg-inline--fa fa-edit w-5 absolute top-0 rtl:right-0 ltr:left-0 rtl:mr-4 mt-3">
                                <path fill="currentColor"
                                      d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                                      class=""></path>
                            </svg>
                            <svg v-if="active && index===0" @click="updateForm" aria-hidden="true" focusable="false"
                                 data-prefix="far"
                                 data-icon="check-square" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                 class="svg-inline--fa fa-edit w-5 absolute top-0 rtl:right-0 ltr:left-0 rtl:mr-4 mt-3">
                                <path fill="#1CC342"
                                      d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"
                                      class=""></path>
                            </svg>
                            <div class="rounded-10px text-center py-4"
                                 :class="(active && index===0) ? 'bg-white-900' :''">
                                <p class="text-sm mb-2 p-title">الوزن</p>
                                <p class="text-primary-900">
                                    <span class="text-xl p-color font-bold" v-if="index!==0 || (index===0 && !active)">{{item.weight}}</span>
                                    <Input class="text-lg font-bold" style="direction: ltr;" v-model="item.weight"
                                           v-if="index===0 && active"/>
                                    <span class="text-base p-color font-bold">KG</span>
                                </p>
                            </div>
                        </div><!--col-->
                        <div class="w-1/3 px-2 2xs:px-1 relative edit-weight-box">
                            <svg @click="openForm" v-if="index===0 && !active" aria-hidden="true" focusable="false"
                                 data-prefix="fas"
                                 data-icon="edit" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                 class="svg-inline--fa fa-edit w-5 absolute top-0 rtl:right-0 ltr:left-0 rtl:mr-4 mt-3">
                                <path fill="currentColor"
                                      d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                                      class=""></path>
                            </svg>
                            <svg v-if="active && index===0" @click="openForm" aria-hidden="true" focusable="false"
                                 data-prefix="far"
                                 data-icon="check-square" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                 class="svg-inline--fa fa-edit w-5 absolute top-0 rtl:right-0 ltr:left-0 rtl:mr-4 mt-3">
                                <path fill="#1CC342"
                                      d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"
                                      class=""></path>
                            </svg>
                            <div class=" rounded-10px text-center py-4"
                                 :class="(active && index===0) ? 'bg-white-900' :''">
                                <p class="text-sm mb-2 p-title">محيط الورك</p>
                                <p class="text-primary-900">
                                    <span class="text-xl p-color font-bold" v-if="index!==0 || (index===0 && !active)">{{item.highest}}</span>
                                    <Input class="text-lg font-bold" style="direction: ltr;" v-model="item.highest"
                                           v-if="index===0 && active"/>
                                    <span class="text-base p-color font-bold">CM</span>
                                </p>
                            </div>
                        </div><!--col-->
                        <div class="w-1/3 px-2 2xs:px-1 relative edit-weight-box">
                            <svg @click="openForm" v-if="index===0 && !active" aria-hidden="true" focusable="false"
                                 data-prefix="fas"
                                 data-icon="edit" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                 class="svg-inline--fa fa-edit w-5 absolute top-0 rtl:right-0 ltr:left-0 rtl:mr-4 mt-3">
                                <path fill="currentColor"
                                      d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                                      class=""></path>
                            </svg>
                            <svg v-if="active && index===0" @click="openForm" aria-hidden="true" focusable="false"
                                 data-prefix="far"
                                 data-icon="check-square" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                 class="svg-inline--fa fa-edit w-5 absolute top-0 rtl:right-0 ltr:left-0 rtl:mr-4 mt-3">
                                <path fill="#1CC342"
                                      d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"
                                      class=""></path>
                            </svg>
                            <div class=" rounded-10px text-center py-4"
                                 :class="(active && index===0) ? 'bg-white-900' :''">
                                <p class="text-sm mb-2 p-title">محيط الخصر</p>
                                <p class="text-primary-900">
                                    <span class="text-xl p-color font-bold" v-if="index!==0 || (index===0 && !active)">{{item.waist}}</span>
                                    <Input class="text-lg font-bold" style="direction: ltr;" v-model="item.waist"
                                           v-if="index===0 && active"/>
                                    <span class="text-sm p-color font-bold">CM</span>
                                </p>
                            </div>
                        </div><!--col-->
                    </div>
                </div>
            </div>

            <div class="bg-green-100 mt-4 rounded-10px text-center" v-if="success">
                <p class="p-3 text-base text-blue-800 font-medium">تمت العملية بنجاح</p>
            </div>

            <div class="flex items-center justify-center relative" v-if="imageModal">
                <div class="fixed inset-0 overlay flex items-center justify-center" @click.self="openImageModal()">
                    <div class="relative w-4/5 3sm:mx-4 bg-white-900 mx-auto flex items-center justify-center rounded-lg custom-shadow px-4 py-4">
                        <img class="w-4 cursor-pointer absolute left-0 top-0 m-4" src="@/assets/img/close.svg"
                             alt="close"
                             @click="openImageModal()">
                        <div class="w-full mb-4">
                            <img class="mx-auto w-10/12 object-cover" src="@/assets/img/waist-hip.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Bar from '../../components/app/Bar';
    import Input from '../../components/app/Input';

    export default {
        data() {
            return {
                selectedChart: 'week',
                active: false,
                success: false,
                errorMsg: false,
                customError: false,
                newWeight: false,
                loading: false,
                gender: null,
                imageModal: false,
                // chartOptions: {
                //     active: false,
                //     chart: {
                //         type: 'spline',
                //         backgroundColor: 'transparent'
                //     },
                //     title: {
                //         text: ''
                //     },
                //     credits: {
                //         enabled: false
                //     },
                //     accessibility: {
                //         announceNewData: {
                //             enabled: true
                //         }
                //     },
                //     xAxis: {
                //         type: 'category',
                //         title: {
                //             text: ''
                //         }
                //     },
                //     yAxis: {
                //         title: {
                //             text: ''
                //         }
                //     },
                //     legend: {
                //         enabled: false,
                //     },
                //
                //     plotOptions: {
                //         series: {
                //             color: '#14123D',
                //             dataLabels: {
                //                 enabled: true,
                //             }
                //         }
                //     },
                //
                //     series: [
                //         {
                //             name: 'معدل الوزن',
                //             data: []
                //         }
                //     ],
                //
                //     responsive: {
                //         rules: [{
                //             condition: {
                //                 maxWidth: 576
                //             },
                //         }]
                //     }
                // },

                form: {
                    weight: null,
                    waist: null,
                    highest: null,
                },
                bodyAll: [],
                itemEdit: null,
                bodyAllByType: [],
            }
        },
        methods: {
            openImageModal() {
                this.imageModal = !this.imageModal;
            },
            openForm(item) {
                this.active = !this.active;
                this.itemEdit = item;
            },
            loadBodyAll() {
                this.axios.get('/mobile/body/all')
                    .then(response => {
                        this.bodyAll = response.data.data;

                    });
            },
            // changeChart(type) {
            //     this.selectedChart = type;
            //     this.chartOptions.series[0].data = [];
            //     this.axios.get(`/mobile/body/chart/${this.selectedChart}`)
            //         .then(response => {
            //             this.bodyAllByType = response.data.data;
            //             this.chartOptions.series[0].data = this.bodyAllByType;
            //         });
            // },
            addWeight() {
                const $this = this;
                this.loading = true;
                this.axios.post('/mobile/body', this.form)
                    .then(res => {
                        this.bodyAll.push(res.data.data);
                        this.success = true;
                        this.loading = false;
                        this.errorMsg = false;
                        this.customError = false;
                        setTimeout(function () {
                            location.reload();
                            $this.success = false;
                            $this.newWeight = false;
                        }, 3000);
                    }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 422) {
                            this.errorMsg = true;
                            this.loading = false;
                            this.form = {
                                weight: 0,
                                waist: 0,
                                highest: 0,
                            };
                            setTimeout(function () {
                                $this.newWeight = false;
                                $this.loading = false;
                                $this.errorMsg = false;
                            }, 3000);
                        }
                        if (error.response.status === 500) {
                            this.customError = true;
                            this.loading = false;
                            setTimeout(function () {
                                $this.customError = false;
                                $this.loading = false;
                            }, 3000);
                        }
                    }
                });
            },
            updateForm() {
                const $this = this;
                this.active = false;
                const $form = this.bodyAll.find(x => {
                    return x.id === this.itemEdit.id
                });
                this.axios.post('/mobile/body/update', $form)
                    .then(res => {
                        this.success = true;
                        setTimeout(function () {
                            $this.success = false;
                            location.reload();
                        }, 3000);
                    });
            },
            AddNewWeight() {
                this.newWeight = !this.newWeight;
            }
        },
        components: {
            Bar,
            Input
        },
        computed: {
            user() {
                return localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null;
            }
        },
        created() {
            this.loadBodyAll();
            // this.changeChart('week');
        }
    }
</script>
<style scoped lang="scss">
    .p-title {
        color: #9393AF;
    }

    .edit-weight-box:not(:first-child) {
        svg {
            display: none;
        }
    }
</style>
