<template>
    <div>
        <Bar :title="'وجبات اليوم'" :close="'/food-plan'"/>
        <div class="pb-4 min-h-screen bg-gray-100 meal-details">
            <div class="relative">
                <div class="relative">
                    <div class="overlay absolute inset-x-0 top-0"></div>
                    <img v-if="form.image" class="w-full" :src="form.image" alt="">
                    <img v-else class="w-full"
                         src="@/assets/img/default-img.png" alt="">
                    <p class="meal-type bg-white-900 rounded-full text-blue-800 text-sm font-light px-6 py-2"
                       v-if="form.type==='1'">وجبة الفطور</p>
                    <p class="meal-type bg-white-900 rounded-full text-blue-800 text-sm font-light px-6 py-2"
                       v-if="form.type==='2'">وجبة الغداء</p>
                    <p class="meal-type bg-white-900 rounded-full text-blue-800 text-sm font-light px-6 py-2"
                       v-if="form.type==='3'">وجبة العشاء</p>
                    <p class="meal-type bg-white-900 rounded-full text-blue-800 text-sm font-light px-6 py-2"
                       v-if="form.type==='4'">وجبة خفيفة</p>
                </div>

                <div class="mx-4" v-if="meal">
                    <div class="sugar-rate relative bg-white-900 pt-2 pb-4 flex justify-between items-end px-9 mb-8">
                        <div class="text-center">
                            <p class="text-base text-blue-800">قبل</p>
                            <p class="text-purple-900 text-4xl font-bold">{{meal.rate_before}}</p>
                            <p class="text-purple-900 text-sm font-bold">mg/dl</p>
                        </div>
                        <div>
                            <div class="text-center meal-rate overflow-hidden purple">
                                <div v-if="meal.rate >=4 "
                                     class="w-full h-full flex items-center justify-center green">
                                    <div>
                                        <p class="rate">{{meal.rate}}</p>
                                    </div>
                                </div>
                                <div v-else-if="meal.rate === '3'"
                                     class="w-full h-full flex items-center justify-center yellow">
                                    <div>
                                        <p class="rate">{{meal.rate}}</p>
                                    </div>
                                </div>
                                <div v-else-if="meal.rate < 3"
                                     class="w-full h-full flex items-center justify-center red">
                                    <div>
                                        <p class="rate">{{meal.rate}}</p>
                                    </div>
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/nothing.svg" alt="">
                                </div>
                            </div>
                            <p class="text-base text-blue-800">قياس سكر الوجبة</p>
                        </div>
                        <div class="text-center">
                            <p class="text-base text-blue-800">بعد</p>
                            <p class="text-purple-900 text-4xl font-bold">{{meal.rate_after}}</p>
                            <p class="text-purple-900 text-sm font-bold">mg/dl</p>
                        </div>
                    </div>
                    <div v-if="meal.feedback">
                        <p class="timing text-center">{{form.date}},<span>{{form.timing}}</span></p>
                        <div class="py-3 px-4 dietitian-reply">
                            <h4 class="text-white-900 text-base font-medium mb-2">أخصائي التغذية</h4>
                            <p class="text-white-900 text-sm font-light">
                                {{meal.feedback}}
                            </p>
                        </div>
                    </div>
                    <div class="no-rate" v-else>
                        <p>سيقوم اخصائي التغذية بتقييم وجبتك لاحقا</p>


                    </div>
                  <div class="no-rate" style="margin-top:20px" v-if="meal.rate_after ==='-' && meal.rate_before ==='-'">
                  <p >يرجى اضافه قياس السكر في الدم بعد ساعتين من تناول الوجبه</p>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CustomCheckbox from '../../components/app/CustomCheckbox'
    import Bar from '../../components/app/Bar';

    export default {
        components: {
            CustomCheckbox,
            Bar
        },
        data() {
            return {
                meal: null,
                form: {
                    meal_id: null,
                    type: '1',
                    date: null,
                    image: '',
                    timing: null,
                    notes: '',
                },
            }
        },
        mounted() {
            let $id = this.$route.params.id;
            if ($id) {
                this.axios.get(`/mobile/meal/${$id}`)
                    .then(response => {
                        this.meal = response.data.data;
                        this.meal.rate_before = this.meal.rate_before || '-';
                        this.meal.rate_after = this.meal.rate_after || '-';

                        this.form = {
                            id: this.meal.id,
                            type: this.meal.type,
                            image: this.meal.image_url,
                            timing: this.meal.timing,
                            notes: this.meal.notes,
                            date: this.meal.date,
                        };
                    });
            }

        },
        computed: {
            user() {
                return localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .meal-details {
        padding-top: 45px;

        .overlay {
            height: 57%;
            transform: rotate(180deg);
            background: linear-gradient(rgba(118, 50, 122, 0) 0%, #14123d 100%);
        }

        .meal-type {
            position: absolute;
            top: 10px;
            right: 15px;
            z-index: 3;
        }
    }

    .sugar-rate {
        border-radius: 0px 0px 5px 5px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    }

    .green {
        background-color: #38B455;
    }

    .yellow {
        background-color: #FFBC07;
    }

    .red {
        background-color: #DB2E2E;
    }

    .purple {
        background-color: #6C306A;
    }

    .meal-rate {
        position: absolute;
        top: -49px;
        width: 98px;
        height: 98px;
        border: 2px solid #fff;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 auto;
        left: 0;
        right: 0;

        .rate {
            font-weight: bold;
            font-size: 49px;
            color: #fff;
            line-height: 1;
        }
    }

    .timing {
        direction: ltr;
        font-weight: normal;
        font-size: 12px;
        color: #9a9cb8;
        margin-bottom: 40px;

        span {
            padding-left: 5px;
        }
    }

    .dietitian-reply {
        border-radius: 15px;
        background-color: #6C306A;

        p {
            line-height: 1.5rem;
        }
    }

    .no-rate {
        margin-top: 30%;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #b4b4c9;
    }
</style>
